<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :value="dialog"
    max-width="360"
    @close="close"
    @input="$emit('dialog')"
    @click:outside="close"
  >
    <v-card :class="$vuetify.breakpoint.xsOnly ? 'fullscreen' : ''" class="request-consult">
      <v-btn class="request-consult__close" icon rounded @click="close">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
      <span class="heading-2">Request a consult</span>
      <PhoneInput
        v-model="phoneNumber"
        :errors="phoneNumberErrors"
        class="my-4"
        fieldName="phoneNumber"
        placeholder="Enter phone number"
      />
      <Select
        v-model="consultationMethod"
        :items="consultationOptions"
        fieldName="consultationMethod"
        label="Consultation Method"
      />
      <v-card-actions class="request-consult__actions pa-0">
        <OutlinedButton size="large" text="Cancel" @onClick="close" />
        <PrimaryButton :loading="buttonLoading" size="large" text="Request" @onClick="requestConsult" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import { DependentsApi } from "@/api/dependents";
import PhoneInput from "@/components/shared/PhoneInput";
import OutlinedButton from "@/components/uikit/OutlinedButton";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import Select from "@/components/uikit/Select";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { isValidPhoneNumber } from "@/utils/validations";

export default {
  name: "Consult",
  props: ["dialog", "dependent"],
  components: {
    Select,
    OutlinedButton,
    PrimaryButton,
    PhoneInput,
  },
  data: () => ({
    consultationMethod: "phoneCall",
    phoneNumber: "",
    buttonLoading: false,
  }),
  validations: {
    phoneNumber: { required },
  },
  computed: {
    consultationOptions() {
      return [
        {
          text: "Phone Call",
          value: "phoneCall",
        },
        {
          text: "Video Call",
          value: "videoCall",
        },
      ];
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      !this.$v.phoneNumber.required &&
        !isValidPhoneNumber(this.phoneNumber) &&
        errors.push(this.$t("validation.required", { field: "phoneNumber" }));
      return errors;
    },
  },
  methods: {
    close() {
      this.$emit("dialog");
      this.$emit("close");
    },
    async requestConsult() {
      this.buttonLoading = true;

      try {
        await DependentsApi.requestConsult({
          consultationMethod: this.consultationMethod,
          phoneNumber: this.phoneNumber,
          birthDate: this.dependent.birthDate,
        });
        this.close();
        const consultationMethod = this.consultationOptions.find((option) => option.value === this.consultationMethod);
        snackBarEventBus.$emit(snackBarEventName, {
          message: `Successfully requested a ${consultationMethod.text} for your dependent ${this.dependent.firstName} ${this.dependent.lastName}`,
          type: "success",
        });
        this.phoneNumber = "";
        this.consultationMethod = "phoneCall";
      } catch (e) {
        console.error(e);
        snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
      }
      this.buttonLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.request-consult {
  position: relative;
  padding: 30px;

  &.fullscreen {
    padding: 30px !important;
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 13px;
    border: 1px solid #e7e8f2;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
