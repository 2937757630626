<template>
  <div class="dependents-list-layout">
    <v-tabs v-model="tab" :show-arrows="false" active-class="tabs_tab_active" class="tabs" color="primary">
      <v-tab class="tabs_tab"> Children</v-tab>
      <v-tab class="tabs_tab"> Family members</v-tab>
      <v-tab-item class="tab-item">
        <DependentsListCards
          :items="tableDependents"
          :loading="loading"
          @openRequestConsultDialog="openRequestConsultDialog"
        />
      </v-tab-item>
      <v-tab-item class="patients-list_tabs_tab_item">
        <DependentsListCards
          :isChildren="false"
          :items="tableDependents"
          :loading="loading"
          :with-invite="true"
          @onRequestInvite="onRequestInvite"
        />
      </v-tab-item>
    </v-tabs>
    <InviteDependentDialog
      :dependent="inviteDependent"
      :value="inviteDependentDialog"
      @close="closeInviteDependentDialog"
      @toggleDialog="closeInviteDependentDialog"
    />
    <Consult :dependent="requestedDependent" :dialog="requestConsultDialog" @close="closeRequestConsultDialog" />
    <Confirm
      :cancelText="$t('general.dialog.cancel')"
      :confirmText="$t('general.dialog.yes')"
      :dialog="deleteDialog"
      text="Are you sure you want to delete?"
      title="Delete"
      @confirm="onDelete"
      @toggleDialog="closeDeleteDialog"
    />
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapState } from "pinia";

import { AuthApi } from "@/api/auth";
import { DependentsApi } from "@/api/dependents";
import { timeDisplayFormat } from "@/components/Clinicians/constants";
import Consult from "@/components/Dependents/Consult";
import Confirm from "@/components/shared/Confirm";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useProfileSettingsStore } from "@/pinia-store/profileSettings";
import { calcAge } from "@/utils/conversion";
import { getStateName } from "@/utils/states";
import DependentsListCards from "@/views/Patient/Dependents/Cards.vue";
import InviteDependentDialog from "@/views/Patient/Dependents/InviteDependentDialog/index.vue";

export default {
  name: "Dependents",
  components: {
    DependentsListCards,
    InviteDependentDialog,
    Confirm,
    Consult,
  },
  data: () => ({
    tab: 0,
    deleteDialog: false,
    deleteItemId: null,
    loading: false,
    dialog: false,
    inviteDependentDialog: false,
    requestConsultDialog: false,
    inviteDependent: {},
    requestedDependent: {},
    dependents: [],
  }),
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    tableDependents() {
      return (this.dependents || [])
        .map(($item) => {
          const practitionerName = $item.practitioner
            ? `${$item?.practitioner?.firstName || ""} ${$item?.practitioner?.lastName || ""}`
            : "";
          return {
            ...$item,
            state: getStateName($item.state),
            birthDate: $item.birthDate ? this.$moment($item.birthDate, "YYYY-MM-DD").format(timeDisplayFormat) : "",
            practitioner: practitionerName,
            checked: false,
          };
        })
        .filter(($item) => {
          return this.tab === 1
            ? calcAge($item.birthDate, timeDisplayFormat) >= 18
            : calcAge($item.birthDate, timeDisplayFormat) < 18;
        });
    },
  },
  methods: {
    ...mapActions(useAuthStore, ["getCustomTokenRoom", "signInWithCustomToken", "refreshToken"]),
    ...mapActions(useAuthStore, ["setCustomToken"]),
    ...mapActions(useProfileSettingsStore, ["getPatientSettings"]),
    closeDeleteDialog() {
      this.deleteDialog = false;
    },
    async closeInviteDependentDialog() {
      this.inviteDependentDialog = false;
      await this.update();
    },
    openRequestConsultDialog(dependent) {
      this.requestedDependent = dependent;
      this.requestConsultDialog = true;
    },
    onRequestInvite(dependent) {
      this.inviteDependent = dependent;
      this.inviteDependentDialog = true;
    },
    closeRequestConsultDialog() {
      this.requestConsultDialog = false;
      this.requestedDependent = {};
    },
    async onDelete() {
      this.loading = true;
      await DependentsApi.destroy(this.deleteItemId);
      this.deleteDialog = false;
      await this.update();
    },
    async onSignIn(item) {
      this.loading = true;

      try {
        const data = await AuthApi.proxyToPatient(item.id);
        this.setCustomToken(data.token);
        await this.signInWithCustomToken();
        // for twilio messages
        window.open("/patient/visits/upcoming/", "_self");
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      this.dialog = false;
      this.loading = true;
      this.dependents = await DependentsApi.getByInviterIdV1(this.uid);
      this.dependents = (this.dependents || []).sort(
        (a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf(),
      );
      this.loading = false;
    },
  },
  async mounted() {
    try {
      await this.update();
    } catch (e) {
      console.error(e);
      snackBarEventBus.$emit(snackBarEventName, { message: e.message || e, type: "error" });
    }
  },
};
</script>

<style lang="scss" scoped>
.dependents-list-layout {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
