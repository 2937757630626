<template>
  <div>
    <Confirm
      :cancelText="$t('general.dialog.cancel')"
      :dialog="value"
      :loading="loading"
      confirmText="Send link"
      max-width="90%"
      text="Please specify the name of the patient and email address or mobile phone for invitation"
      title="Send Invite"
      width="470px"
      @confirm="submitInvite"
      @toggleDialog="toggleDialog"
    >
      <v-container class="no-padding-bottom no-padding-top activate-patient-dialog">
        <v-row :style="!Object.keys(patient).length && false && { 'pointer-events': 'none' }">
          <v-col cols="6">
            <BaseInput
              v-model="firstName"
              :errors="firsNameErrors"
              :placeholder="$t('general.inputs.firstName')"
              @blur="$v.firstName.$touch()"
              @change="onFirstNameChange"
            />
          </v-col>
          <v-col cols="6">
            <BaseInput
              v-model="lastName"
              :errors="lastNameErrors"
              :placeholder="$t('general.inputs.lastName')"
              @blur="$v.lastName.$touch()"
              @change="onLastNameChange"
            />
          </v-col>
          <v-col cols="12">
            <v-tabs
              :hide-slider="false"
              :show-arrows="false"
              :value="tabValue"
              background-color="transparent"
              class="tab_methods"
            >
              <v-tab class="tab_methods__tab" @click="inviteMethod = 'email'">Invite by Email</v-tab>
              <v-tab class="tab_methods__tab" @click="inviteMethod = 'phone'">Invite by Phone</v-tab>
            </v-tabs>
            <BaseInput
              v-if="inviteMethod === 'email'"
              v-model="email"
              :errors="emailErrors"
              :placeholder="$t('general.inputs.email')"
              @blur="$v.email.$touch()"
              @change="onEmailChange"
            />
            <PhoneInput
              v-else
              v-model="phoneNumber"
              :errors="phoneNumberErrors"
              :placeholder="$t('general.inputs.phone')"
              @blur="$v.phoneNumber.$touch()"
              @change="onPhoneNumberChange"
            />
          </v-col>

          <v-col cols="12">
            <p class="note">
              You can change email address or mobile phone number here in case patient lose access to it
            </p>
          </v-col>
          <v-col cols="12">
            <BaseInput
              v-model="socialSecurityNumber"
              :errors="socialSecurityNumberErrors"
              :hide-details="true"
              class="mb-4"
              fieldName="socialSecurityNumber"
              maskPattern="xxxx-xx-####"
              placeholder="Last 4 digits of Social Security Number"
              @blur="$v.socialSecurityNumber.$touch()"
            />
          </v-col>
          <v-col cols="12">
            <DatePicker
              v-model="birthDate"
              :dense="true"
              :errors="birthDateErrors"
              :height="47"
              :hide-details="true"
              :maxDate="new Date().toISOString().substr(0, 10)"
              @blur="$v.birthDate.$touch()"
              @setDate="changeCurrentDate"
            />
          </v-col>
        </v-row>
      </v-container>
    </Confirm>
    <SuccessDialog :dialog="successDialog" :text="$t(`patients.invite.success`)" @on-close="successDialog = false" />
  </div>
</template>
<script>
import orderBy from "lodash/orderBy";
import { mapActions, mapState } from "pinia";
import { alpha, email, required } from "vuelidate/lib/validators";

import { InvitesApi } from "@/api/invites";
import Confirm from "@/components/shared/Confirm";
import DatePicker from "@/components/shared/DatePicker";
import PhoneInput from "@/components/shared/PhoneInput";
import SuccessDialog from "@/components/shared/SuccessDialog";
import BaseInput from "@/components/uikit/BaseInput";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useInvitesStore } from "@/pinia-store/invites";
import { usePatientsStore } from "@/pinia-store/patients";
import { usePractitionersStore } from "@/pinia-store/practitioners";
import { RolesEnum } from "@/types/Roles.enum";
import { defaultLocale } from "@/utils/constants";
import { isValidEmail, isValidPhoneNumber } from "@/utils/validations";

export default {
  name: "InviteDependentDialog",
  props: ["value", "dependent"],
  components: {
    PhoneInput,
    DatePicker,
    Confirm,
    BaseInput,
    SuccessDialog,
  },
  data: () => ({
    inviteMethod: "email",
    successDialog: false,
    email: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    locale: defaultLocale,
    loading: false,
    invitingId: "",
    patient: {},
    id: "",
    birthDate: "",
    socialSecurityNumber: "",
  }),
  validations() {
    const validations = {
      email: {},
      phoneNumber: {},
      firstName: { required, alpha },
      lastName: { required, alpha },
      birthDate: { required },
      socialSecurityNumber: { required },
    };
    if (this.inviteMethod === "phone") {
      validations.phoneNumber = { required };
    }
    if (this.inviteMethod === "email") {
      validations.email = { required, email };
    }
    return validations;
  },

  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useAuthStore, ["role"]),
    ...mapState(usePractitionersStore, ["practitioners"]),
    ...mapState(usePatientsStore, ["patients"]),
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty || isValidPhoneNumber(this.cleanPhone)) return errors;
      !this.$v.email.email && errors.push(this.$t("validation.email"));
      !this.$v.email.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.email") }));
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];

      if (!this.$v.phoneNumber.$dirty || isValidEmail(this.email)) return errors;
      if (!this.cleanPhone || !isValidPhoneNumber(this.cleanPhone))
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.phone") }));
      return errors;
    },
    tabValue() {
      return this.inviteMethod === "email" ? 0 : 1;
    },
    isSubmitDisabled() {
      return !this.socialSecurityNumber || this.birthDateErrors || this.emailErrors;
    },
    birthDateErrors() {
      const errors = [];
      if (!this.$v.birthDate.$dirty) return errors;
      !this.$v.birthDate.required && errors.push(this.$t("validation.required", { field: "Date of birth" }));
      return errors;
    },
    patientErrors() {
      const errors = [];
      if (!this.$v.id.$dirty) return errors;
      !this.$v.id.required && errors.push(this.$t("validation.required", { field: "Patient" }));
      return errors;
    },
    socialSecurityNumberErrors() {
      const errors = [];
      if (!this.$v.socialSecurityNumber.$dirty) return errors;
      !this.$v.socialSecurityNumber.required &&
        errors.push(this.$t("validation.required", { field: "Social security number" }));
      return errors;
    },
    firsNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.alpha && errors.push(this.$t("validation.name"));
      !this.$v.firstName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.firstName") }));
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.alpha && errors.push(this.$t("validation.name"));
      !this.$v.lastName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.lastName") }));
      return errors;
    },
    emailOrPhoneErrors() {
      if (!this.email && !this.phoneNumber) return true;
      if (this.email && !this.phoneNumber && !isValidEmail(this.email)) return true;
      if (this.phoneNumber && !isValidPhoneNumber(this.phoneNumber)) return true;
      return false;
    },
    selectPatients() {
      return orderBy(
        this.patients.map((i) => ({
          ...i,
          text: `${i.firstName || ""} ${i.lastName || " "}`,
          sortColumn: `${i.firstName || ""} ${i.lastName || " "}`.toLowerCase(),
        })),
        ["sortColumn"],
      );
    },
    isPractitioner() {
      return this.role === RolesEnum.Practitioner;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          if (this.dependent.email) {
            this.email = this.dependent.email;
            this.inviteMethod = "email";
          }
          this.firstName = this.dependent.firstName ?? "";
          this.birthDate = this.dependent.birthDate ?? "";
          this.socialSecurityNumber = this.dependent.socialSecurityNumber ?? "";
          this.lastName = this.dependent.lastName ?? "";
          if (this.dependent.phoneNumber) {
            this.phoneNumber = this.dependent.phoneNumber;
            this.inviteMethod = "phone";
          }
        }
      },
    },
  },
  methods: {
    ...mapActions(useInvitesStore, ["getAllInvites"]),
    changeCurrentDate(newDate) {
      this.birthDate = newDate;
    },
    onFirstNameChange(value) {
      this.firstName = value;
    },
    onLastNameChange(value) {
      this.lastName = value;
    },
    toggleDialog() {
      this.$emit("toggleDialog");
    },
    toggleSuccessDialog() {
      this.successDialog = !this.successDialog;
    },
    onEmailChange(value) {
      this.email = value;
    },
    onPhoneChange(value) {
      this.phoneNumber = value;
    },
    clearFields() {
      this.patient = {};
      this.email = "";
      this.phoneNumber = "";
      this.socialSecurityNumber = "";
      this.birthDate = "";
    },
    async submitInvite() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.emailOrPhoneErrors) {
        snackBarEventBus.$emit(snackBarEventName, {
          message: "Please specify the email address or mobile phone for invitation",
          type: "error",
        });
      }
      try {
        this.loading = true;
        const body = {
          id: this.dependent.id,
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.phoneNumber,
          patientId: this.dependent.id,
          socialSecurityNumber:
            this.socialSecurityNumber?.length === 4
              ? this.socialSecurityNumber
              : this.socialSecurityNumber.split("xxx-xx-")[1],
          locale: this.locale,
          birthDate: this.birthDate,
        };
        await InvitesApi.sendActivationProfile(body);

        this.toggleDialog();
        this.toggleSuccessDialog();
        this.clearFields();
        this.$emit("success");
      } catch (err) {
        console.error(err);
        if (err.isAxiosError) {
          err.message = err.response.data.message;
        }
        snackBarEventBus.$emit(snackBarEventName, { message: err.message || err, type: "error" });
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.tab_methods {
  width: 100%;
  margin-bottom: 30px;

  &__tab {
    width: 100%;
    text-transform: none;
    font-size: 14px;
  }
}

.activate-patient-dialog {
  .note {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #33343e;

    opacity: 0.4;
  }
}
</style>
